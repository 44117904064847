

.slick{
	z-index: 1;
	&-holder{
		position: relative;
	}
	&-slide{
		position: relative;
		z-index: 1;
		img{
			width: 100%;
			vertical-align: top;
		}
	}
	&-slideholder{
		.ACenter;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&-wind{
		padding: (44px/@bfz)*1em (15px/@bfz)*1em;
		background: rgba(255, 255, 255, 0.8);
		/* Blur */
		flex: 1 1 auto;
		max-width:(885px/@bfz)*1em;
		backdrop-filter: blur(40px);
		text-align: center;
		/* Note: backdrop-filter has minimal browser support */

		border-radius: 30px;
	}
	&-p{
		font-family: Comfortaa;
		@tfz:26px;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (29px/@tfz)*1em;
		text-align: center;
		color: #363935;

	}
	&-h1{
		font-family: Comfortaa;
		font-weight: @fw-bold;
		
		@tfz:60px;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (67px/@tfz)*1em;
		text-align: center;
		color: #363935;

	}
	&-btn{
		.ACenter();
		margin: auto;
		background-color: transparent;
		border: none;
		box-shadow: none;
		color: #fff;
		@tfz:52px;
		font-size:(@tfz/@bfz)*1em ;
		height: (52px/@tfz)*1em;
		width: (36px/@tfz)*1em;
		min-width: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		border-radius: 0;
		z-index: 10;
		&:hover{
			background-color: transparent;
			color: #82A84D;
		}
	}
	&-prev{right: auto;}
	&-next{left: auto;}
	&-btns{
		.ACenter();
		margin: 0 (65px/@bfz)*1em;
	}
	&-dts{
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: (36px/@bfz)*1em;
		left: 0;
		right: 0;
		z-index: 2;
		button{
			width: (15px/@bfz)*1em;
			height: (15px/@bfz)*1em;
			opacity: 0;
			max-width: 100%;
			min-width: 1px;
			min-height: 1px;
			padding: 0;
		}
		ul{
			padding: 0;
			margin: 0;
			display: flex;
		}
		li{
			margin: 0 (4px/@bfz)*1em;
			border: (1px/@bfz)*1em solid #fff;
			background-color: transparent;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			overflow: hidden;
			&.slick-active{
				background: #82A84D;
			}
		}
	}
}