.galery{
	&-cont{
		display: flex;
		flex-direction: column;
	}
	&-bg{
		position: relative;
		.container{
			position: relative;
			z-index: 2;
		}
		&:before,&:after{
			content: '';
			display: block;
			position: absolute;
			z-index: 1;
			background-size: cover;

		}
		&:before{
			width: (165px/@bfz)*1em;
			height: (204px/@bfz)*1em;
			left: 0;
			bottom: (291px/@bfz)*1em;
			background-image: url(../img/bg/1.png);
			
		}
		&:after{
			right: 0;
			bottom: 0;
			background-image: url(../img/bg/2.png);
			width: (180px/@bfz)*1em;
			height: (205px/@bfz)*1em;
		}
	}
}
.switch{
	&-top{
		display: flex;
		justify-content: space-around;
		margin-bottom: 20px;
	}
	&-discr{
		display: flex;
		align-items: center;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		justify-content: center;
		margin: auto;
		background-color: #fff;
		width: 72%;
		
		height: (90px/@bfz)*1em;
		border-top-right-radius: (50px/@bfz)*1em;
		border-bottom-right-radius: (50px/@bfz)*1em;
		padding: 0 (36px/@bfz)*1em;
		transition: all .3s;
		z-index: 2;
		p{
			margin: 0;
			font-family: SF UI Display;
			@tfz:24;
			font-size:(@tfz/@bfz)*1em ;
			line-height: (29/@tfz)*1em;
			color: #333333;
		}
		&-center{
			display: flex;
			align-items: center;
			&:before{
				@tfz:54;
				font-size:(@tfz/@bfz)*1em;
			}
		}
		
	}
	&-item{
		position: relative;
		margin: 0px (20px/@bfz)*1em;
		flex: 1 1 auto;
		overflow: hidden;
		display: inline-block;
		cursor: pointer;
		&:first-child{
			margin-left: 0;
		}
		&:last-child{
			margin-right: 0;
		}
		img{
			vertical-align: top;
		}
		&:before{
			.ACenter;
			display: block;
			content: '';
			background: #82A84D;
			opacity: 0;
			z-index: 2;
			transition: opacity .6;
		}
		&:hover,&.active{
			.switch-discr{
				width: 100%;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		&.active{
			&:before{
				opacity: .6;
			}
		}
		&.active .footer{
			&-left,&-right{
				width: 50%;
				&:after{
					opacity: 0;
				}
			}
		}
		.footer{
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: (20px/@bfz)*1em;
			display: flex;
			z-index: 2;

			&-left,&-right{
				transition: width .3s;
				background-color: #fff;
				width: 50%;
				flex: 1 1 auto;

				position: relative;
				&:after{
					content: '';
					display: block;
					background-color: #fff;
					z-index: 2;
					.ACenter();
					opacity: 1;
					transition: opacity .3s;

				}
				&:before{
					content: '';
					display: block;
					position: absolute;
					border: (20px/@bfz)*1em solid transparent;
					border-bottom: 0 solid transparent;
				}
			}
			&-left{
				margin-right: (20px/@bfz)*1em;
				&:before{
					border-left: (20px/@bfz)*1em solid #fff;
					left: 100%;
				}
				&:after{
					right: (-20px/@bfz)*1em;
				}
			}
			&-right{
				margin-left: (20px/@bfz)*1em;
				&:before{
					border-right: (20px/@bfz)*1em solid #fff; 
					right: 100%;
				}
				&:after{
					left: (-20px/@bfz)*1em;
				}
			}
		}
	}
	&-container{
		background: #FFFFFF;
		box-shadow: 0 (2px/@bfz)*1em (20px/@bfz)*1em rgba(0, 0, 0, 0.15);
		padding: (50px/@bfz)*1em (64px/@bfz)*1em;
		margin-bottom: (21px/@bfz)*1em;
	}
	&-h1{
		font-family: Comfortaa;
		font-style: normal;
		font-weight: @fw-bold;
		@tfz:44px;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (49px/@tfz)*1em;
		text-align: center;
		/* Text Black */
		color: #363935;
		margin-bottom: (60px/@tfz)*1em;
		text-align: center;
	}
	
	&-content{

		hr{
			border: (1px/@bfz)*1em solid #C4C4C4;
		}
		display: none;

		&.active{
			display: block;
		}
		&__cont{
			padding-top: (71px/@bfz)*1em;
			padding-bottom: (68px/@bfz)*1em;
			display: flex;
			flex-direction: row;
			border: (1px/@bfz)*1em solid #C4C4C4;
			border-right: none;
			border-left: none;
		}
		&__text{
			width: (558/@bfz)*1em;
			h1{
				font-family: Comfortaa;
				font-style: normal;
				font-weight: bold;
				@tfz:44px;
				font-size:(@tfz/@bfz)*1em ;;
				line-height: (49px/@tfz)*1em;
				color: #333333;
				margin-bottom: (44px/@tfz)*1em;
			}
			p{
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				@tfz:16px;
				font-size:(@tfz/@bfz)*1em ;;
				line-height: (24px/@tfz)*1em;
				color: #606673;
			}
		}
		&__left,&__right{
			flex:1 1 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__right img{
			width: 100%;
			
		}
		&__footer{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: (52px)*1em;

		}

	}

}