.qform{
	background-image: url(../img/qforbg.png);
	padding: (70/@bfz)*1em 0;
	&-wind{
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur( (40/@bfz)*1em);
		border-radius: (30/@bfz)*1em;
		text-align: center;
		padding: (50/@bfz)*1em 0;
		max-width: (800/@bfz)*1em;
		margin: auto;
	}
	&-h1{
		font-family: Comfortaa;
		font-style: normal;
		font-weight: bold;
		@tfz:42;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (47/@tfz)*1em;
		margin: 0;
		margin-bottom: (29/@tfz)*1em;
		text-align: center;
	}
	&-btn{
		padding-left: 0;
		padding-right: 0;
		width: auto;
		text-align: center;
		font-weight: @fw-bold;
		@tfz:14;
		font-size:(@tfz/@bfz)*1em ;
		min-width: (200/@tfz)*1em;
		flex:0 1 auto;
	}
	&-input-holder{
		display: flex;
		width: (451/@bfz)*1em;
		justify-content: center;
		margin: auto;
		background-color: #fff;
		border-radius: (50/@bfz)*1em;
		input{
			box-shadow: none;
			border: none;
			flex: 1 1 auto;
			width: 100%;
			min-width: 1;
			font-family: Comfortaa;
			font-style: normal;
			font-weight: @fw-bold;
			outline: none;
			@tfz:12;
			padding-top: (4/@tfz)*1em;
			font-size:(@tfz/@bfz)*1em ;
			border-radius: (50/@tfz)*1em;
			padding-left:(19/@tfz)*1em;
			&:place-holder{
				color: #BCBCBC;
			}
		}
	}
}