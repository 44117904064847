@font-face {
  font-family: 'fontello';
  src: url('../font/fontello/font/fontello.eot');
  src: url('../font/fontello/font/fontello.eot#iefix') format('embedded-opentype'),
  url('../font/fontello/font/fontello.woff2') format('woff2'),
  url('../font/fontello/font/fontello.woff') format('woff'),
  url('../font/fontello/font/fontello.ttf') format('truetype'),
  url('../font/fontello/font/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


@import (inline) url(fontello/fontello-codes.css);
//@import (inline) url(bootstrap.min.css);
@import (inline) url(baze.css);
//begin slick
@import  url(../../node_modules/slick-carousel/slick/slick.less);
//end slick

//begin lightgallery
@import  (inline) url(../../node_modules/fancybox/dist/css/jquery.fancybox.css);
//end lightgallery

button,.btn{


  display: inline-block;
  border: (2px/@bfz)*1em solid #82A84D;
  box-sizing: border-box;
  border-radius: (50px/@bfz)*1em;
  transition: background-color .3s, color .3s;

  background-color: #82A84D;
  color:#fff;

  
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: (14px/@bfz)*1em;
  line-height: 137.88%;
  min-width: (200px/@bfz)*1em;
  text-align: center;

  /* Text Black */
  
  border-radius: (50px/@bfz)*1em;

  text-align: center;
  padding: (8px/@bfz)*1em (25px/@bfz)*1em;

  cursor: pointer;
  outline: none!important;
  text-decoration: none;

  &:hover,&.active{
    background-color: transparent;
    color: #363935;
 }
 &.sf{
    font-family: SFUIDisplay;
    @tfz:16px;
    font-size:(@tfz/@bfz)*1em ;
    height: (50px/@tfz)*1em;
    text-align: center;
  }
}
.pos{
  &-r{
    position: relative;
  }
}
@import url(myTools.3.2.less);
@import url(fonts.less);
@import url(main.less);
@import url(header.less);
@import url(slider.less);
@import url(prod.less);
@import url(adv.less);
@import url(partners.less);
@import url(gallery.less);
@import url(banner.less);
@import url(achiv.less);
@import url(sertif.less);
@import url(contacts.less);
@import url(footer.less);
@import url(growo.less);
@import url(integ.less);
@import url(bred.less);
@import url(qform.less);


@import url(addiptive.less);



