.fancybox-overlay.fancybox-overlay-fixed {
	background: rgba(204, 204, 204, 0.4392156862745098);

	
}

a.fancybox-item.fancybox-close {
	background: #fff;
	border-radius: 50%;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before{
		font-family: "fontello";
		content: '\e809';
		color:#000;
		font-size: (25px/@bfz)*1em;
	}
}
a.fancybox-nav
{
	opacity: 0;
	background: rgba(204, 204, 204, 0.5);
	transition: opacity .3s;
	&:hover{
		opacity: 1;
	}
}
a.fancybox-nav.fancybox-next span ,
a.fancybox-nav.fancybox-prev span {
	
	display: flex;
	justify-content: center;
	align-items: center;
	transition: visibility .3s;
	&:before{
		font-family: "fontello";
		content: '\e80c';
		color:#0F0;
		font-size: (25px/@bfz)*1em;
		color: #000;
		text-shadow: 0 0 5px #fff;
	}
}
a.fancybox-nav.fancybox-next span:before {
	content: '\e80d';
	
}
.gallery{
	img{
		width: 100%;
	    margin-left: -(3px/@bfz)*1em;
    margin-right: -(3px/@bfz)*1em;
    width: 101%;
    margin-top: -(3px/@bfz)*1em;
    margin-bottom: -(3px/@bfz)*1em;
	}

}