.contacts{
	position: relative;
	&:before,&:after{
		content: '';
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		width: 50%;
		height: 100%;
		background-color: #82A84D;
		opacity: .9;


	}
	&:before{
		background-image: url(../img/cdecor.png);
		opacity: 1;
	}
	&-half{
		width: 50%;
	}
	.form-holder{
		width: (562/@bfz)*1em;
		margin: auto;
		margin-top: (70/@bfz)*1em;
		margin-bottom: (90/@bfz)*1em;
		max-width: 100%;
	}
	&-input,&-textarea{
		font-family: Comfortaa;
		font-style: normal;
		font-weight: normal;
		@tfz:14;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (22/@tfz)*1em;
		color: #363935;
		border: none;
		box-shadow: none;
		border-bottom: (1/@tfz)*1em solid #363935;
		margin: (20/@tfz)*1em 0;
		outline: none;
		&::placeholder{
			color: rgba(54, 57, 53, 0.6);

		}
	}
	&-textarea{
		min-height: (70/@bfz)*1em
	}
	&-form{
		display: flex;
		flex-direction: column;
	}
	&-header{
		font-family: Comfortaa;
		font-style: normal;
		font-weight: bold;
		font-size: (42/@bfz)*1em;
		line-height: 170.66%;
		color: #363935;
		&.white{
			color:#fff;
		}
	}
	&-cont{
		display: flex;
		position: relative;
		z-index: 2;
	}
	&-radioholder{
		display: flex;
		justify-content: space-between;
		padding: 0 (26/@bfz)*1em;
	}
	&-radio{
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: (29/@bfz)*1em;
		&:before{
			flex:0 0 auto;
			margin: auto 0;
			width: (24/@bfz)*1em;
			height: (24/@bfz)*1em;
			border:(2/@bfz)*1em solid #363935;
			border-radius: 50%;
			content: '';
			margin-right: (5/@bfz)*1em;
		}
		input{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			min-width: 1px;
			min-height: 1px;
			opacity: 0;
			cursor: pointer;

		}
		label{
			font-family: Comfortaa;
			font-style: normal;
			font-weight: bold;
			@tfz:16;
			font-size:(@tfz/@bfz)*1em ;
			line-height: (18/@tfz)*1em;
			color:#363935;
		}
		&.active label{
			color: #82A84D;
		}
		&.active{
			&:before{
				background-color: #82A84D;
				box-shadow: inset 0 0 0 (3/@bfz)*1em #fff;
				border-color: #82A84D;
			}

		}
	}
	&-info{
		font-family: "SFUIDisplay";
		@tfz:24;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (34/@tfz)*1em;
		color: #FFFFFF;
		text-decoration: none;
	}
	&-phone{
		position: relative;
		padding-left: (57/@bfz)*1em;
		&:before{
			color: #FFFFFF;
			@tfz:52;
			font-size:(@tfz/@bfz)*1em ;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			display: flex;
			align-items: center;
		}
	}
	&-mb{
		margin-bottom: (50/24)*1em;
	}
	&-address{
		display: flex;
		&:before{
			@tfz:52;
			width: (40/52)*1em;
			font-size:@tfz/24*1em;
			text-align: left;
		}
		p{
			margin: 0;
		}
	}
	&-mail{
		&:before{
			font-size: (33/24)*1em;
			width: auto;
		}
	}
	&-social{
		margin-top: (115/@bfz)*1em;
		a{
			@tfz:44;
			font-size:(@tfz/@bfz)*1em ;
			color: #fff;
			text-decoration: none;
		}
	}

	
}