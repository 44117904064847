.partners{
	display: flex;
	align-items: center;
	justify-content: center;
	&-link{
		margin: (10px/@bfz)*1em;
		flex:1 1 auto;
		position: relative;
		img{
			display: block;
			vertical-align: top;
			max-width: 100%;
		}
		.hover{
			.ACenter();
			margin: auto;
			opacity: 0;
			transition: opacity .3s;
		}
		&:hover{
			.hover{
				opacity: 1;
			}
		}
	}
}