.bred{
	padding:(15/@bfz)*1em 0;
	margin: 0;
	display: flex;
	list-style: none;
	li{
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		@tfz:14;
		font-size:(@tfz/@bfz)*1em ;
		line-height: 150.06%;
		a{
			color: inherit;
			font-family: inherit;
			font-size: inherit;
			text-decoration: none;
		}
		&:after{
			content: '->';
			margin: 0 3px;
		}
		&:last-child:after{
			display: none;
		}
	}
}