.achiv{
	&-loop{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&-img{
		width: (110px/@bfz)*1em;
		height: (112px/@bfz)*1em;
	}
	&-item{
		display: flex;
		flex-wrap: nowrap;
		margin: 0 (50px/@bfz)*1em;
		&:first-child{
			margin-left: 0;
		}
		&:last-child{
			margin-right: 0;
		}

	}
	&-digits{
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		@tfz:70px;
		font-size:(@tfz/@bfz)*1em ;
		/* or 120px */
		text-transform: uppercase;

		/* Green */

		color: #82A84D;
		margin: 0;
	}
	&-discr{
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		@tfz:26px;
		font-size:(@tfz/@bfz)*1em ;
		/* identical to box height, or 45px */

		text-transform: uppercase;

		/* Text Black */

		color: #363935;
		margin: 0;
	}
}