.tfooter{
	position: relative;
	background-color: #363935;
	padding-top: (36/@bfz)*1em;
	padding-bottom: (18/@bfz)*1em;
	&:before{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: auto;
		background-image: url(../img/footerbg.png);
		content: '';
		opacity: .6;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.container{
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
	}
	p,a{
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		@tfz:16;
		font-size:(@tfz/@bfz)*1em ;
		line-height: 2;
		text-decoration: none;
		color: #FFFFFF;
		margin: 0;
		font-weight: 300;
	}
	.col{
		flex:1 1 auto;
		width: 33%;
		&-logo{
			flex:0 0 auto;
			width: auto;
		}
	}
	&-logo{
		width: (127/16)*1em;
		height: (124/16)*1em;
		margin: auto;
		display: block;
		margin-bottom: 18;
	}
	.text-holder{
		max-width: (376/@bfz)*1em;
		margin: auto;
	}
	.menu-holder{
		display: flex;
		justify-content: space-around;
		margin: auto;
		max-width: (376/@bfz)*1em;
		ul{
			padding: 0;
			margin: 0;
			list-style: none;
			font-weight: 500;
			li a{
				font-weight: 500;
			}
		}
	}
}