.test{
	background:#c00;
	box-shadow: inset 0px 0px 0px 1px #000;
	min-width: 10px;
	min-height: 10px;
	width: 100%;
	height: 100%;
	color: #000;
}

@bfz:12px;
body{
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex: auto;
  font-family: Roboto;
  font-size: @bfz;
  main.content{
    flex: auto;
    display: -webkit-flex;
    display: flex;
  }
}
html{
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container{
  width: 100%;
  padding: 0 (66px/@bfz)*1em;
}


.block{
  display: block;
  width: auto;
  max-width: 100%;
  width: 100%;
}
.main-h1{
  margin-top: (90px/@bfz)*1em;
  padding: (40px/@bfz)*1em 0;
  h1{
    font-family: Comfortaa;
    font-style: normal;
    font-weight: @fw-bold;
    @tfz:60px;
    font-size:(@tfz/@bfz)*1em ;
    line-height: (67px/@tfz)*1em;
    text-align: center;
    margin: (17px/@tfz)*1em 0;
  }
  /* Text Black */

  color: #363935;
  &__p{
    font-family: Comfortaa;
    font-style: normal;
    font-weight: normal;
    @tfz:18px;
    font-size:(@tfz/@bfz)*1em ;
    line-height: (28px/@tfz)*1em;
    text-align: center;
    letter-spacing: 0.2em;
    margin: (17px/@tfz)*1em 0;

    /* Small text */

    color: #5E7D32;
  }

}
.container{
  width: (1885/@bfz)*1em;
  margin: auto;
  max-width: 100%
}


.text{
  &-center{
    text-align: center;
  }
}
