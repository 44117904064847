@media (max-width: 1682px) {
	.achiv-item {
		margin: 10px;
	}
	body{
		font-size: 11px;
	}

}

@lfz:9px;

@media (max-width: 1220px) {
	body{
		font-size: 9px;
	}
	.switch-discr p{
		line-height: 1;
	}
	.top-cont{
		width: 46%;
	}
}

@media (max-width: 1020px)
{
	.top-slog {
		width: 100%;
	}

	.top-container.container {
		flex-wrap: wrap;
	}

	.top-cont {
		width: 100%;
	}

	.lang {
		margin-left: auto;
	}
	.bottom-container .btns{
		width: auto;
		flex: 0 0 auto;
	}
	.bottom .menu-btn{
		margin-left: auto;
		@tfz:10;
		font-size: (10/@lfz)*1em;
		width: (25/@tfz)*1em;
		max-width: (25/@tfz)*1em;
		min-width: (25/@tfz)*1em;
		height: (25/@tfz)*1em;
		padding: 0px;
		
		display: none;
		border-radius: 0px;
		position: relative;
		order: 2;

		border-radius: 3px;



		span{
			width: 90%;
			height: (3/@tfz)*1em;
			background-color: #fff;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			
			&:first-child{
				margin-top: (2/@tfz)*1em;
			}
			&:last-child{
				margin-bottom: (2/@tfz)*1em;
			}

			transition: margin-top .3s .3s,margin-bottom .3s .3s, transform .3s ;
		}
		&:hover span{
			background-color: #82a84d;
		}
		&.active{
			span{
				background-color: #82a84d;
				transform: rotate(45deg);
				transition: margin-top .3s,margin-bottom .3s, transform .3s .3s;
				&:first-child{
					margin-top: (9/@tfz)*1em;
				}
				&:last-child{
					margin-bottom: (9/@tfz)*1em;
					transform: rotate(-45deg);
				}
			}
		}
	}
	.bottom .menu{
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		background-color: #fff;
		width: 100%;
		min-width: 100%;
		padding-bottom: 5px;
	}
	.slick-wind{
		font-size: 6px;
	}

	.switch-item {
		width: 100%;
		height: (120/@lfz)*1em;
		border: 1px solid #cccc;
		margin: 0px;
	}

	.switch-top {
		flex-wrap: wrap;
	}

	.switch-item img {
		width: 100%;
	}

	.switch-item .footer {
		display: none;
	}
	.switch-content__cont {
		flex-wrap: wrap;
	}

	.switch-content__left {
		width: 100%;
		flex: 1 1 100%;
		padding: 50px;
		padding-top: 0px;
	}

	.advantages-item {
		width: 100%;
		flex: 1 1 100%;
		max-width: 100%;
	}

	.achiv-item {
		width: 50%;
		flex: 1 1 50%;
		max-width: 50%;
		margin: 0px;
		padding: 10px;
	}

	.achiv-loop {
		flex-wrap: wrap;
	}




	.container.contacts-cont {
		flex-wrap: wrap;
	}

	.contacts-half {
		width: 100%;

	}

	.contacts:before,.contacts:after {
		height: 50%;
		bottom: 0px;
		top: auto;
		left: 0px;
		width:100%
	}
}
@media (max-width: 500px)
{
	.lang{
		//margin-left: 0;
	}
	.top-container.container{
		padding: 0 15px;
	}
	.top-cont {
		flex-wrap: wrap;
	}
	ul.social {
		//margin-left: auto;
	}

	.top-cont {
		flex-wrap: wrap;
		text-align: center;
	}



	.top-container.container {}

	.bottom-container .btns .btn {
		width: 50%;
	}

	.bottom-container .btns button {
		//width: 50%;
		margin: 3px;
		min-width: 100px;
	}

	.bottom-container .btns {
		width: auto;
		max-width: 100%;
		margin: 0px;
		flex: 1 1 auto;

	}

	.bottom-container .btns button:first-child {
		margin-right: 3px;
	}

	.container.bottom-container {
		padding: 0px 15px;
	}
	.bottom .menu-btn{
		display: block;
	}
	.btns {
		justify-content: center;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
	}

	.bottom .menu {
		flex-wrap: wrap;
	}

	.bottom .menu a {
		display: block;
		width: 100%;
		margin: 5px 0px;
	}

	.bottom .menu {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		background-color: #fff;
		width: 100%;
		min-width: 100%;
		padding-bottom: 5px;
		
		flex-wrap: wrap;
		font-size: 12px;

		max-height: 0px;
		overflow: hidden;
		padding: 0px;
		transition: max-height .3s, padding 0s .3s;
		&.active{
			transition: max-height .3s;
			padding: 15px;
			max-height: 999px;
		}
	}

	.switch-container {
		padding: 15px;
	}
	.switch-content__left {
		padding: 20px;
	}

	.switch-container {
		padding: 15px;
	}

	.switch-content__left {
		padding: 20px;
	}

	.advantages-item {
		flex-wrap: wrap;
		background: #fff;
	}

	.advantages-img img {
		position: static;
	}

	.advantages-row {
		padding: 15px;
	}

	
	.tfooter .container {
		flex-wrap: wrap;
	}

	.tfooter .container .col {
		width: 100%;
		order: 2;
	}

	.tfooter .container .col-logo {
		order: 1;
	}

	.tfooter .text-holder {
		margin: 0px;
	}

	.slick-dts{
		bottom: auto;
		top: 100%;
		padding-top: 20px;
	}

	.slick-wind {
		background: rgba(255, 255, 255, 0.4);
		backdrop-filter: blur(0px);
	}
	.container {
		padding: 0px;
	}
	.main-h1 h1 {
		font-size: 30px;
	}
	.advantages-img {
		order: 2;
	}

	a.partners-link {
		max-width: 42%;
	}

	.partners {
		flex-wrap: wrap;
	}
	.tfooter .text-holder {
		margin: 20px auto;
	}

	.col.col-logo {
		text-align: center;
	}

	.switch-discr {
		width: 72%;
		right: 0;
		border-radius: 30px;
	}

	.switch-item.active .switch-discr,.switch-item:hover .switch-discr {
		width: auto;
		width: 72%;
		border-radius: 0;
	}
	.form-holder {
		padding: 0px 15px;
	}
	.switch-content__text p {
		font-size: 15px;
	}

	.advantages-text .text p {
		font-size: 15px;
	}
	.achiv-item {
		display: block;
		max-width: 100%;
		text-align: center;

	}

	header{
		.top-container.container {
			display: none;
			&.active{
				display: block;
			}
		}
	}

}

@media (max-width: 400px){
	.achiv-item {
		display: block;
		max-width: 100%;
		text-align: center;

	}
}