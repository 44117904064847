@screen-xs-min:0px;
@screen-xs-lg-min:480px;
@screen-sm-min:768px;
@screen-md-min:992px;
@screen-lg-min:1200px;
@grid-columns:12;
@screen-xs-max:(@screen-sm-min - 1);
@screen-xs-xs-max:(@screen-xs-lg-min - 1);
@screen-xs-lg-max:(@screen-sm-min - 1);
@screen-sm-max:(@screen-md-min - 1);
@screen-md-max:(@screen-lg-min - 1);

@phone: 		~"(min-width: @{screen-xs-min})";
@phone-max:		~"(max-width:@{screen-xs-max})"	;
@phone-max-xs:	~"(max-width:@{screen-xs-xs-max})";
@phone-min-lg:	~"(min-width:@{screen-xs-xs-max})";
@phone-max-lg:	~"(max-width:@{screen-xs-lg-max})";
@tablet: 		~"(min-width: @{screen-sm-min})";
@tablet-max:	~"(max-width:@{screen-sm-max})"	;
@desktop: 		~"(min-width: @{screen-md-min})";
@desktop-max:	~"(max-width:@{screen-md-max})"	;
@large:			~"(min-width: @{screen-lg-min})";

@phone-area:	~"@{phone} and @{phone-max}"	;
@phone-xs-area:	~"@{phone} and @{phone-max-xs}"	;
@phone-lg-area:	~"@{phone-min-lg} and @{phone-max-lg}"	;
@tablet-area:	~"@{tablet} and @{tablet-max}"	;
@desktop-area:	~"@{desktop} and @{desktop-max}";


.pos(@position: inherit,@left: inherit,@right: inherit,@top: inherit,@bottom: inherit){
	position:@position;
	left:@left;
	right:@right;
	top:@top;
	bottom:@bottom;
}
.ACenter(@position: absolute,@top: 0,@bottom: 0,@right: 0,@left: 0,@margin: auto){
	.pos(@position:@position,@left: @left,@right: @right,@top:@top,@bottom: @bottom);
	margin:@margin;
}
.def(@cont:'',@display: block,@color:transparent){
	content: @cont;
	display: @display;
	background-color: @color;
}
.transition(@name:all,@t:0.5s,@tFunction:ease,@d:0s){
	-webkit-transition: @name @t @tFunction @d;
	-moz-transition: @name @t @tFunction @d;
	-ms-transition: @name @t @tFunction @d;
	-o-transition: @name @t @tFunction @d;
	transition: @name @t @tFunction @d;
}
@bsize:12px;
.fz(@size:16px,@bsize:@bsize)
{
	font-size: @size/@bsize*1em;
}
.font(@fontname,@fontpath,@fontpref,@fweight,@fital){
	@font-face {
		font-family: @fontname;
		src: url("@{fontpath}/@{fontname}@{fontpref}.eot");
		src: url("@{fontpath}/@{fontname}@{fontpref}.eot?#iefix")format("embedded-opentype"),
		url("@{fontpath}/@{fontname}@{fontpref}.woff") format("woff"),
		url("@{fontpath}/@{fontname}@{fontpref}.ttf") format("truetype");
		font-style: @fital;
		font-weight: @fweight;
	}
}
//font
@thin: 100;
@light: 300;
@regular: 400;
@medium: 500;
@bold: 700;
@black: 900;
@path:"../font/Roboto";
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Thin",@fweight:@thin,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Thin",@fweight:@thin,@fital:italic);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Light",@fweight:@light,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Light",@fweight:@light,@fital:italic);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Regular",@fweight:@regular,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Regular",@fweight:@regular,@fital:italic);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Medium",@fweight:@medium,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Medium",@fweight:@medium,@fital:italic);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Bold",@fweight:@bold,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Bold",@fweight:@bold,@fital:italic);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Black",@fweight:@black,@fital:normal);
.font(@fontname:'Roboto',@fontpath:@path , @fontpref:"-Black",@fweight:@black,@fital:italic);







