.grow{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: (73/@bfz)*1em	;
	&-item{
		padding: (28/@bfz)*1em (57/@bfz)*1em;
		background: #FFFFFF;
		box-shadow: 0 (4/@bfz)*1em (30/@bfz)*1em rgba(84, 106, 77, 0.2);
		max-width: (678/@bfz)*1em;
		min-width: 300px;
		margin: (16/@bfz)*1em (11/@bfz)*1em;
		flex:1 1 40%;
		position: relative;
	}
	&-icon{
		color:#5E7D32;
		@tfz:49;
		font-size:(@tfz/@bfz)*1em ;

	}
	&-text &-h1{
		font-family: Comfortaa;
		font-style: normal;
		font-weight: bold;
		@tfz:28;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (31/@tfz)*1em;
		margin: (24/@tfz)*1em 0;

		/* dark */

		color: #333333;
	}
	&-text{
		p{
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			@tfz:16;
			font-size:(@tfz/@bfz)*1em ;
			line-height: (24/@tfz)*1em;
			color: #606673;
		}
	}
	&-btn{
		font-family: SFUIDisplay;
		@tfz:16;
		font-size:(@tfz/@bfz)*1em ;
		line-height: (19/@tfz)*1em;
		text-align: center;
		border-radius: (50/@tfz)*1em;
		margin-top: (41/@tfz)*1em;
	}
	&-call{
		position: absolute;
		top: 0px;
		right: 0px;
		color:#fff;
		z-index: 1;
		width: (112/@bfz)*1em;
		height: (112/@bfz)*1em;
		padding: (14/@bfz)*1em (8/@bfz)*1em	;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		overflow: hidden;
		padding: (10/@bfz)*1em;
		padding-left: (42/@bfz)*1em;
		padding-bottom: (47/@bfz)*1em;
		cursor: pointer;
		&:before{
			width: (204/@bfz)*1em;
			height: (204/@bfz)*1em;
			background:#82A84D;
			content: '';
			display: block;
			border-radius: 50%;
			z-index: -1;
			position: absolute;
			top: -(92/@bfz)*1em;
			right:  -(92/@bfz)*1em;
			transition:background .3s;
		}
		i{
			transition:font-size .3s;
			font-size: (30/@bfz)*1em;

		}
		p{
			transition:font-size .3s;
			font-family: SFUIDisplay;
			@tfz:14.6667;
			font-size:(@tfz/@bfz)*1em ;;
			line-height: (18/@bfz)*1em;
			/* identical to box height */
			margin: 0;

			text-align: center;

			color: #FFFFFF;
		}
	}
	&-item.active &-form{
		opacity: 1;
		visibility: visible;
	}
	&-item.active &-call{
		z-index: 2;
		i,p{font-size: 0;}
		&:before{
			background-color: #5E7D32;
		}
	}
	&-form{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		padding: (28/@bfz)*1em (57/@bfz)*1em;
		background: #FFFFFF;
		opacity: 0;
		visibility: hidden;
		transition: opacity .3s;
		padding: (64/@bfz)*1em (91/@bfz)*1em (20/@bfz)*1em (79/@bfz)*1em;
		form{
			height: 100%;
			display: flex;
			flex-direction: column;

			justify-content: space-around;
		}

		input{
			width: 100%;
			display: block;
			border: none;
			box-shadow: none;
			border-bottom: 1px solid #363935;
			padding-bottom: (4/@bfz)*1em;
			margin: auto;
			transition: opacity .3s;

			&:placeholder{
				font-family: Comfortaa;
				font-style: normal;
				font-weight: normal;
				@tfz:14;
				font-size:(@tfz/@bfz)*1em ;
				line-height: (22/@tfz)*1em;

				color: #9E9E9E;

				opacity: 0.8;
			}
		}
		.btn{
			width: 100%;
			margin-top: auto;
		}
		.contacts-radioholder{
			min-width: 100px;
			flex-wrap: wrap;
		}
		.contacts-radio{
			margin-bottom: 5px;
		}

	}


}