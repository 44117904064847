.slick-sertifficate{
	.slick{
		&-item{
			padding: (50px/@bfz)*1em;
		}
		&-arrow{
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			color:#C4C4C4;
		}
	}
}
.sertifficate{
	background-color: #F4F4F4;
}