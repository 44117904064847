@font-face {
  font-family: 'fontello';
  src: url('../font/fontello/font/fontello.eot');
  src: url('../font/fontello/font/fontello.eot#iefix') format('embedded-opentype'), url('../font/fontello/font/fontello.woff2') format('woff2'), url('../font/fontello/font/fontello.woff') format('woff'), url('../font/fontello/font/fontello.ttf') format('truetype'), url('../font/fontello/font/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-linkedin:before { content: '\e803'; } /* '' */
.icon-twit:before { content: '\e804'; } /* '' */
.icon-arrow-down:before { content: '\e805'; } /* '' */
.icon-chevron-left:before { content: '\e806'; } /* '' */
.icon-chevron-right:before { content: '\e807'; } /* '' */
.icon-turkey:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-wheat:before { content: '\e80a'; } /* '' */
.icon-chicken:before { content: '\e80b'; } /* '' */
.icon-left-dir:before { content: '\e80c'; } /* '' */
.icon-right-dir:before { content: '\e80d'; } /* '' */
.icon-phone-call:before { content: '\e80e'; } /* '' */
.icon-placeholder:before { content: '\e80f'; } /* '' */
.icon-envelope:before { content: '\e810'; } /* '' */
.icon-vk-social-logotype:before { content: '\e811'; } /* '' */
.icon-facebook-logo-button:before { content: '\e812'; } /* '' */
.icon-googleplus-logo:before { content: '\e813'; } /* '' */
.icon-ring:before { content: '\e814'; } /* '' */
.icon-paa:before { content: '\e815'; } /* '' */
.icon-pad:before { content: '\e816'; } /* '' */
.icon-hay-rolls:before { content: '\e817'; } /* '' */
.icon-industry:before { content: '\e818'; } /* '' */
.icon-truck:before { content: '\e819'; } /* '' */
.icon-fruit:before { content: '\e81a'; } /* '' */
.icon-hends:before { content: '\e81b'; } /* '' */
/*MAIN STYLES*/
body{
  padding: 0px;
  margin: 0px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}
*:before,*:after{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



/* loader */
.icon-load {
  background: url(../img/loader.gif) no-repeat;
  background-color:#fff;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: fixed;
  top:  0px;
  left:   0px;
  right:  0px;
  bottom: 0px;
  display: none;
  z-index: 1000;
}
.loaded .icon-load {
  display: block;
}
/*CONTENT*/
.content {
  width: 100%;
  min-width: 320px;
  text-align: left;
}

.test{
  display: block;
  width: 100%;
  height: 100%;
  min-height: 10px;
  min-width: 10px;
  background: #c00;
  box-shadow: inset 0px 0px 0px 1px #000;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("../img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("../img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("../img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: visible !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("../img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("../img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("../img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

button,
.btn {
  display: inline-block;
  border: 0.16666667em solid #82A84D;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s;
  background-color: #82A84D;
  color: #fff;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 1.16666667em;
  line-height: 137.88%;
  min-width: 16.66666667em;
  /* Text Black */
  border-radius: 4.16666667em;
  text-align: center;
  padding: 0.66666667em 2.08333333em;
  cursor: pointer;
  outline: none!important;
  text-decoration: none;
}
button:hover,
.btn:hover,
button.active,
.btn.active {
  background-color: transparent;
  color: #363935;
}
button.sf,
.btn.sf {
  font-family: SFUIDisplay;
  font-size: 1.33333333em;
  height: 3.125em;
  text-align: center;
}
.pos-r {
  position: relative;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Thin.eot");
  src: url("../font/Roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Thin.woff") format("woff"), url("../font/Roboto/Roboto-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Thin.eot");
  src: url("../font/Roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Thin.woff") format("woff"), url("../font/Roboto/Roboto-Thin.ttf") format("truetype");
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Light.eot");
  src: url("../font/Roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Light.woff") format("woff"), url("../font/Roboto/Roboto-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Light.eot");
  src: url("../font/Roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Light.woff") format("woff"), url("../font/Roboto/Roboto-Light.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Regular.eot");
  src: url("../font/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Regular.woff") format("woff"), url("../font/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Regular.eot");
  src: url("../font/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Regular.woff") format("woff"), url("../font/Roboto/Roboto-Regular.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Medium.eot");
  src: url("../font/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Medium.woff") format("woff"), url("../font/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Medium.eot");
  src: url("../font/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Medium.woff") format("woff"), url("../font/Roboto/Roboto-Medium.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Bold.eot");
  src: url("../font/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Bold.woff") format("woff"), url("../font/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Bold.eot");
  src: url("../font/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Bold.woff") format("woff"), url("../font/Roboto/Roboto-Bold.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Black.eot");
  src: url("../font/Roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Black.woff") format("woff"), url("../font/Roboto/Roboto-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Roboto';
  src: url("../font/Roboto/Roboto-Black.eot");
  src: url("../font/Roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("../font/Roboto/Roboto-Black.woff") format("woff"), url("../font/Roboto/Roboto-Black.ttf") format("truetype");
  font-style: italic;
  font-weight: 900;
}
@font-face {
  font-family: 'Comfortaa';
  src: url("../font/Comfortaa/Comfortaa-Light.eot");
  src: url("../font/Comfortaa/Comfortaa-Light.eot?#iefix") format("embedded-opentype"), url("../font/Comfortaa/Comfortaa-Light.woff") format("woff"), url("../font/Comfortaa/Comfortaa-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Comfortaa';
  src: url("../font/Comfortaa/Comfortaa-Regular.eot");
  src: url("../font/Comfortaa/Comfortaa-Regular.eot?#iefix") format("embedded-opentype"), url("../font/Comfortaa/Comfortaa-Regular.woff") format("woff"), url("../font/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Comfortaa';
  src: url("../font/Comfortaa/Comfortaa-Medium.eot");
  src: url("../font/Comfortaa/Comfortaa-Medium.eot?#iefix") format("embedded-opentype"), url("../font/Comfortaa/Comfortaa-Medium.woff") format("woff"), url("../font/Comfortaa/Comfortaa-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Comfortaa';
  src: url("../font/Comfortaa/Comfortaa-Semi-bold.eot");
  src: url("../font/Comfortaa/Comfortaa-Semi-bold.eot?#iefix") format("embedded-opentype"), url("../font/Comfortaa/Comfortaa-Semi-bold.woff") format("woff"), url("../font/Comfortaa/Comfortaa-Semi-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Comfortaa';
  src: url("../font/Comfortaa/Comfortaa-Bold.eot");
  src: url("../font/Comfortaa/Comfortaa-Bold.eot?#iefix") format("embedded-opentype"), url("../font/Comfortaa/Comfortaa-Bold.woff") format("woff"), url("../font/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Black/SFUIDisplay-Black.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Black/SFUIDisplay-Black.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Black/SFUIDisplay-Black.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Black/SFUIDisplay-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Bold/SFUIDisplay-Bold.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Bold/SFUIDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Bold/SFUIDisplay-Bold.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Bold/SFUIDisplay-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Light/SFUIDisplay-Light.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Light/SFUIDisplay-Light.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Light/SFUIDisplay-Light.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Light/SFUIDisplay-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Medium/SFUIDisplay-Medium.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Medium/SFUIDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Medium/SFUIDisplay-Medium.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Medium/SFUIDisplay-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Regular/SFUIDisplay-Regular.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Regular/SFUIDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Regular/SFUIDisplay-Regular.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Regular/SFUIDisplay-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Semibold/SFUIDisplay-Semibold.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Semibold/SFUIDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Semibold/SFUIDisplay-Semibold.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Semibold/SFUIDisplay-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Thin/SFUIDisplay-Thin.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Thin/SFUIDisplay-Thin.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Thin/SFUIDisplay-Thin.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Thin/SFUIDisplay-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'SFUIDisplay';
  src: url("../font/SFUIDisplay/SFUIDisplay-Ultralight/SFUIDisplay-Ultralight.eot");
  src: url("../font/SFUIDisplay/SFUIDisplay-Ultralight/SFUIDisplay-Ultralight.eot?#iefix") format("embedded-opentype"), url("../font/SFUIDisplay/SFUIDisplay-Ultralight/SFUIDisplay-Ultralight.woff") format("woff"), url("../font/SFUIDisplay/SFUIDisplay-Ultralight/SFUIDisplay-Ultralight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'Montserrat';
  src: url("../font/Montserrat/Montserrat-bold.eot");
  src: url("../font/Montserrat/Montserrat-bold.eot?#iefix") format("embedded-opentype"), url("../font/Montserrat/Montserrat-bold.woff") format("woff"), url("../font/Montserrat/Montserrat-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
.test {
  background: #c00;
  box-shadow: inset 0px 0px 0px 1px #000;
  min-width: 10px;
  min-height: 10px;
  width: 100%;
  height: 100%;
  color: #000;
}
body {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex: auto;
  font-family: Roboto;
  font-size: 12px;
}
body main.content {
  flex: auto;
  display: -webkit-flex;
  display: flex;
}
html {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.container {
  width: 100%;
  padding: 0 5.5em;
}
.block {
  display: block;
  width: auto;
  max-width: 100%;
  width: 100%;
}
.main-h1 {
  margin-top: 7.5em;
  padding: 3.33333333em 0;
  /* Text Black */
  color: #363935;
}
.main-h1 h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 700;
  font-size: 5em;
  line-height: 1.11666667em;
  text-align: center;
  margin: 0.28333333em 0;
}
.main-h1__p {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5em;
  line-height: 1.55555556em;
  text-align: center;
  letter-spacing: 0.2em;
  margin: 0.94444444em 0;
  /* Small text */
  color: #5E7D32;
}
.container {
  width: 157.08333333em;
  margin: auto;
  max-width: 100%;
}
.text-center {
  text-align: center;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
}
header p,
header a {
  color: #363935;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16666667em;
  line-height: 150.06%;
  margin: 0;
  text-decoration: none;
}
header p:hover,
header a:hover {
  text-decoration: none;
}
.top {
  border-bottom: 0.08333333em solid #E6E6E6;
}
.top-container {
  display: flex;
  line-height: 3.08333333em;
  align-items: center;
}
.top-cont {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.top-cont a {
  margin: 0 0.71428571em;
}
.top-cont a:before {
  margin-right: 0.41666667em;
}
.bottom {
  box-shadow: 0px 22px 20px -20px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.bottom.scrool {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.bottom-container {
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.bottom-container .btns {
  flex: 1 1 auto;
  margin-left: auto;
  text-align: right;
}
.bottom-container .btns button:first-child {
  margin-right: 2.08333333em;
}
.bottom .logo img {
  max-width: 5.33333333em;
  max-height: 5.33333333em;
}
.bottom .menu {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-around;
  max-width: 55%;
}
.bottom .menu-btn {
  display: none;
}
.social {
  padding: 0;
  display: flex;
  list-style: none;
  margin: 0;
}
.social a {
  margin: 0 0.41666667em;
  color: #9E9E9E;
}
.social a:before {
  margin: 0;
}
.lang {
  position: relative;
  margin-left: 1.66666667em;
}
.lang-select {
  display: flex;
  align-items: center;
}
.lang-select:before {
  order: 2;
  font-size: 0.83333333em;
}
.lang-top {
  display: flex;
  cursor: pointer;
}
.lang-top img {
  width: 1.66666667em;
  height: 1.66666667em;
}
.lang.active .lang-hider {
  max-height: 999px;
}
.lang-hider {
  overflow: hidden;
  list-style: none;
  max-height: 0px;
  position: absolute;
  margin: auto;
  margin-top: 0.66666667em;
  top: 100%;
  z-index: 1;
  right: 0;
  padding: 0;
  width: 10.83333333em;
}
.lang-list {
  margin: 0;
  border: 0.08333333em solid #E6E6E6;
  border-radius: 0.5em;
  list-style: none;
  padding: 0.83333333em 1.66666667em;
  background-color: #fff;
}
.lang-list li {
  display: flex;
  padding: 5px 0;
}
.lang-list li img {
  margin-right: 0.41666667em;
}
.slick {
  z-index: 1;
}
.slick-holder {
  position: relative;
}
.slick-slide {
  position: relative;
  z-index: 1;
}
.slick-slide img {
  width: 100%;
  vertical-align: top;
}
.slick-slideholder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slick-wind {
  padding: 3.66666667em 1.25em;
  background: rgba(255, 255, 255, 0.8);
  /* Blur */
  flex: 1 1 auto;
  max-width: 73.75em;
  backdrop-filter: blur(40px);
  text-align: center;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 30px;
}
.slick-p {
  font-family: Comfortaa;
  font-size: 2.16666667em;
  line-height: 1.11538462em;
  text-align: center;
  color: #363935;
}
.slick-h1 {
  font-family: Comfortaa;
  font-weight: 700;
  font-size: 5em;
  line-height: 1.11666667em;
  text-align: center;
  color: #363935;
}
.slick-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  font-size: 4.33333333em;
  height: 1em;
  width: 0.69230769em;
  min-width: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 0;
  z-index: 10;
}
.slick-btn:hover {
  background-color: transparent;
  color: #82A84D;
}
.slick-prev {
  right: auto;
}
.slick-next {
  left: auto;
}
.slick-btns {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin: 0 5.41666667em;
}
.slick-dts {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 3em;
  left: 0;
  right: 0;
  z-index: 2;
}
.slick-dts button {
  width: 1.25em;
  height: 1.25em;
  opacity: 0;
  max-width: 100%;
  min-width: 1px;
  min-height: 1px;
  padding: 0;
}
.slick-dts ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.slick-dts li {
  margin: 0 0.33333333em;
  border: 0.08333333em solid #fff;
  background-color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  overflow: hidden;
}
.slick-dts li.slick-active {
  background: #82A84D;
}
.galery-cont {
  display: flex;
  flex-direction: column;
}
.galery-bg {
  position: relative;
}
.galery-bg .container {
  position: relative;
  z-index: 2;
}
.galery-bg:before,
.galery-bg:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
  background-size: cover;
}
.galery-bg:before {
  width: 13.75em;
  height: 17em;
  left: 0;
  bottom: 24.25em;
  background-image: url(../img/bg/1.png);
}
.galery-bg:after {
  right: 0;
  bottom: 0;
  background-image: url(../img/bg/2.png);
  width: 15em;
  height: 17.08333333em;
}
.switch-top {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.switch-discr {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  margin: auto;
  background-color: #fff;
  width: 72%;
  height: 7.5em;
  border-top-right-radius: 4.16666667em;
  border-bottom-right-radius: 4.16666667em;
  padding: 0 3em;
  transition: all .3s;
  z-index: 2;
}
.switch-discr p {
  margin: 0;
  font-family: SF UI Display;
  font-size: 2em;
  line-height: 1.20833333em;
  color: #333333;
}
.switch-discr-center {
  display: flex;
  align-items: center;
}
.switch-discr-center:before {
  font-size: 4.5em;
}
.switch-item {
  position: relative;
  margin: 0px 1.66666667em;
  flex: 1 1 auto;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}
.switch-item:first-child {
  margin-left: 0;
}
.switch-item:last-child {
  margin-right: 0;
}
.switch-item img {
  vertical-align: top;
}
.switch-item:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  content: '';
  background: #82A84D;
  opacity: 0;
  z-index: 2;
  transition: opacity .6;
}
.switch-item:hover .switch-discr,
.switch-item.active .switch-discr {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.switch-item.active:before {
  opacity: .6;
}
.switch-item.active .footer-left,
.switch-item.active .footer-right {
  width: 50%;
}
.switch-item.active .footer-left:after,
.switch-item.active .footer-right:after {
  opacity: 0;
}
.switch-item .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.66666667em;
  display: flex;
  z-index: 2;
}
.switch-item .footer-left,
.switch-item .footer-right {
  transition: width .3s;
  background-color: #fff;
  width: 50%;
  flex: 1 1 auto;
  position: relative;
}
.switch-item .footer-left:after,
.switch-item .footer-right:after {
  content: '';
  display: block;
  background-color: #fff;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 1;
  transition: opacity .3s;
}
.switch-item .footer-left:before,
.switch-item .footer-right:before {
  content: '';
  display: block;
  position: absolute;
  border: 1.66666667em solid transparent;
  border-bottom: 0 solid transparent;
}
.switch-item .footer-left {
  margin-right: 1.66666667em;
}
.switch-item .footer-left:before {
  border-left: 1.66666667em solid #fff;
  left: 100%;
}
.switch-item .footer-left:after {
  right: -1.66666667em;
}
.switch-item .footer-right {
  margin-left: 1.66666667em;
}
.switch-item .footer-right:before {
  border-right: 1.66666667em solid #fff;
  right: 100%;
}
.switch-item .footer-right:after {
  left: -1.66666667em;
}
.switch-container {
  background: #FFFFFF;
  box-shadow: 0 0.16666667em 1.66666667em rgba(0, 0, 0, 0.15);
  padding: 4.16666667em 5.33333333em;
  margin-bottom: 1.75em;
}
.switch-h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 700;
  font-size: 3.66666667em;
  line-height: 1.11363636em;
  /* Text Black */
  color: #363935;
  margin-bottom: 1.36363636em;
  text-align: center;
}
.switch-content {
  display: none;
}
.switch-content hr {
  border: 0.08333333em solid #C4C4C4;
}
.switch-content.active {
  display: block;
}
.switch-content__cont {
  padding-top: 5.91666667em;
  padding-bottom: 5.66666667em;
  display: flex;
  flex-direction: row;
  border: 0.08333333em solid #C4C4C4;
  border-right: none;
  border-left: none;
}
.switch-content__text {
  width: 46.5em;
}
.switch-content__text h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 3.66666667em;
  line-height: 1.11363636em;
  color: #333333;
  margin-bottom: 1em;
}
.switch-content__text p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.33333333em;
  line-height: 1.5em;
  color: #606673;
}
.switch-content__left,
.switch-content__right {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.switch-content__right img {
  width: 100%;
}
.switch-content__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 52px;
}
.advantages-container {
  padding: 0;
  overflow: hidden;
}
.advantages-cont,
.advantages-row {
  display: flex;
  flex-direction: column;
}
.advantages-item {
  background: #FFFFFF;
  box-shadow: 0 0.33333333em 1.66666667em rgba(0, 0, 0, 0.15);
  flex: 1 1 75%;
  max-width: 75%;
  margin: 2.75em 0;
  margin-left: auto;
  display: flex;
  min-width: 55%;
  position: relative;
  z-index: 2;
  left: 100%;
  transition: left .3s, right .3s;
}
.advantages-row {
  position: relative;
}
.advantages-row .advantages-dec {
  position: absolute;
  left: 0;
  bottom: 10.41666667em;
  z-index: 1;
}
.advantages-row:nth-child(2n) .advantages-item {
  margin-left: 0;
  margin-right: auto;
  left: auto;
  right: 100%;
}
.advantages-row:nth-child(2n) .advantages-item .advantages-text {
  order: 1;
}
.advantages-row:nth-child(2n) .advantages-dec {
  right: 0;
  left: auto;
}
.advantages-item.show {
  left: 0;
}
.advantages-row:nth-child(2n) .advantages-item.show {
  right: 0;
}
.advantages-text,
.advantages-img {
  flex: 1 1 50%;
}
.advantages-img {
  position: relative;
  overflow: hidden;
}
.advantages-img img {
  position: absolute;
  margin: auto;
  vertical-align: top;
  width: auto;
  min-height: 100%;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
}
.advantages-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.advantages-text .text {
  padding: 0 5.41666667em;
  display: block;
}
.advantages-text .text h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 3.66666667em;
  line-height: 1.11363636em;
  margin-bottom: 1em;
}
.advantages-text .text p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.33333333em;
  line-height: 1.5em;
  color: #606673;
}
.advantages-text .text .btn {
  width: auto;
  min-width: inherit;
  margin-top: 2.8125em;
  margin-bottom: 1.25em;
}
.advantages .partners-link {
  width: 100px;
}
.advantages .partners {
  flex-wrap: wrap;
}
.advantages .achiv-item {
  width: 100%;
  max-width: 100%;
  flex: 0 0 auto;
  width: 200px;
}
.advantages-arrow .advantages-text .text {
  padding-left: 0;
  margin: 4.16666667em 0;
}
.advantages-arrow .advantages-text .text h1 {
  font-size: 3.33333333em;
}
.advantages-arrow .advantages-text .text h1:before {
  font-size: 1.4em;
  color: #82A84D;
  margin-right: 0.42857143em;
}
.advantages-arrow .advantages-item {
  box-shadow: none;
  width: 80%;
}
.advantages-arrow .advantages-item:before {
  content: '';
  width: 11.91666667em;
  height: 13em;
  position: absolute;
  bottom: 100%;
  margin: auto;
  margin-bottom: 1.66666667em;
  background-image: url(../img/darrow.png);
  background-size: auto 100%;
  transform: rotateY(180deg);
  right: 20%;
}
.advantages-arrow .advantages-row:first-child .advantages-item:before {
  display: none;
}
.advantages-arrow .advantages-row:nth-child(2n) .advantages-item:before {
  transform: rotateY(0deg);
  right: initial;
  left: 20%;
}
.advantages-arrow .advantages-row:nth-child(2n) .advantages-item .advantages-text .text {
  padding-right: 0;
  padding-left: 5em;
}
@media (max-width: 1020px) {
  .advantages-arrow .advantages-item {
    width: 100%;
    margin-bottom: 100px;
  }
  .advantages-arrow .advantages-item .text {
    padding: 0 15px;
  }
  .advantages-arrow .advantages-item:before {
    right: 10%;
  }
  .advantages-arrow .advantages-row:nth-child(2n) .advantages-item:before {
    left: 10%;
  }
}
@media (max-width: 500px) {
  .advantages-arrow .advantages-row:nth-child(2n) .advantages-item .advantages-text .text {
    padding-left: 15px;
  }
}
.partners {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners-link {
  margin: 0.83333333em;
  flex: 1 1 auto;
  position: relative;
}
.partners-link img {
  display: block;
  vertical-align: top;
  max-width: 100%;
}
.partners-link .hover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: opacity .3s;
}
.partners-link:hover .hover {
  opacity: 1;
}
.fancybox-overlay.fancybox-overlay-fixed {
  background: rgba(204, 204, 204, 0.43921569);
}
a.fancybox-item.fancybox-close {
  background: #fff;
  border-radius: 50%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
a.fancybox-item.fancybox-close:before {
  font-family: "fontello";
  content: '\e809';
  color: #000;
  font-size: 2.08333333em;
}
a.fancybox-nav {
  opacity: 0;
  background: rgba(204, 204, 204, 0.5);
  transition: opacity .3s;
}
a.fancybox-nav:hover {
  opacity: 1;
}
a.fancybox-nav.fancybox-next span,
a.fancybox-nav.fancybox-prev span {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: visibility .3s;
}
a.fancybox-nav.fancybox-next span:before,
a.fancybox-nav.fancybox-prev span:before {
  font-family: "fontello";
  content: '\e80c';
  color: #0F0;
  font-size: 2.08333333em;
  color: #000;
  text-shadow: 0 0 5px #fff;
}
a.fancybox-nav.fancybox-next span:before {
  content: '\e80d';
}
.gallery img {
  width: 100%;
  margin-left: -0.25em;
  margin-right: -0.25em;
  width: 101%;
  margin-top: -0.25em;
  margin-bottom: -0.25em;
}
.banner-holder {
  text-align: center;
  position: relative;
  z-index: 2;
}
.banner-img {
  max-width: 102.91666667em;
  border-radius: 1.66666667em;
  margin: 6.25em auto;
  display: block;
}
.banner-img img {
  max-width: 100%;
}
.banner-decor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.banner-decor__1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 13.25em;
  height: 16em;
}
.banner-decor__2 {
  position: absolute;
  bottom: 5.83333333em;
  left: 0;
  width: 12.58333333em;
  height: 17.5em;
}
.achiv-loop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.achiv-img {
  width: 9.16666667em;
  height: 9.33333333em;
}
.achiv-item {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 4.16666667em;
}
.achiv-item:first-child {
  margin-left: 0;
}
.achiv-item:last-child {
  margin-right: 0;
}
.achiv-digits {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 5.83333333em;
  /* or 120px */
  text-transform: uppercase;
  /* Green */
  color: #82A84D;
  margin: 0;
}
.achiv-discr {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 2.16666667em;
  /* identical to box height, or 45px */
  text-transform: uppercase;
  /* Text Black */
  color: #363935;
  margin: 0;
}
.slick-sertifficate .slick-item {
  padding: 4.16666667em;
}
.slick-sertifficate .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #C4C4C4;
}
.sertifficate {
  background-color: #F4F4F4;
}
.contacts {
  position: relative;
}
.contacts:before,
.contacts:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 50%;
  height: 100%;
  background-color: #82A84D;
  opacity: .9;
}
.contacts:before {
  background-image: url(../img/cdecor.png);
  opacity: 1;
}
.contacts-half {
  width: 50%;
}
.contacts .form-holder {
  width: 46.83333333em;
  margin: auto;
  margin-top: 5.83333333em;
  margin-bottom: 7.5em;
  max-width: 100%;
}
.contacts-input,
.contacts-textarea {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16666667em;
  line-height: 1.57142857em;
  color: #363935;
  border: none;
  box-shadow: none;
  border-bottom: 0.07142857em solid #363935;
  margin: 1.42857143em 0;
  outline: none;
}
.contacts-input::placeholder,
.contacts-textarea::placeholder {
  color: rgba(54, 57, 53, 0.6);
}
.contacts-textarea {
  min-height: 5.83333333em;
}
.contacts-form {
  display: flex;
  flex-direction: column;
}
.contacts-header {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 3.5em;
  line-height: 170.66%;
  color: #363935;
}
.contacts-header.white {
  color: #fff;
}
.contacts-cont {
  display: flex;
  position: relative;
  z-index: 2;
}
.contacts-radioholder {
  display: flex;
  justify-content: space-between;
  padding: 0 2.16666667em;
}
.contacts-radio {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2.41666667em;
}
.contacts-radio:before {
  flex: 0 0 auto;
  margin: auto 0;
  width: 2em;
  height: 2em;
  border: 0.16666667em solid #363935;
  border-radius: 50%;
  content: '';
  margin-right: 0.41666667em;
}
.contacts-radio input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  min-width: 1px;
  min-height: 1px;
  opacity: 0;
  cursor: pointer;
}
.contacts-radio label {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 1.33333333em;
  line-height: 1.125em;
  color: #363935;
}
.contacts-radio.active label {
  color: #82A84D;
}
.contacts-radio.active:before {
  background-color: #82A84D;
  box-shadow: inset 0 0 0 0.25em #fff;
  border-color: #82A84D;
}
.contacts-info {
  font-family: "SFUIDisplay";
  font-size: 2em;
  line-height: 1.41666667em;
  color: #FFFFFF;
  text-decoration: none;
}
.contacts-phone {
  position: relative;
  padding-left: 4.75em;
}
.contacts-phone:before {
  color: #FFFFFF;
  font-size: 4.33333333em;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
}
.contacts-mb {
  margin-bottom: 2.08333333em;
}
.contacts-address {
  display: flex;
}
.contacts-address:before {
  width: 0.76923077em;
  font-size: 2.16666667em;
  text-align: left;
}
.contacts-address p {
  margin: 0;
}
.contacts-mail:before {
  font-size: 1.375em;
  width: auto;
}
.contacts-social {
  margin-top: 9.58333333em;
}
.contacts-social a {
  font-size: 3.66666667em;
  color: #fff;
  text-decoration: none;
}
.tfooter {
  position: relative;
  background-color: #363935;
  padding-top: 3em;
  padding-bottom: 1.5em;
}
.tfooter:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-image: url(../img/footerbg.png);
  content: '';
  opacity: .6;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.tfooter .container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
}
.tfooter p,
.tfooter a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.33333333em;
  line-height: 2;
  text-decoration: none;
  color: #FFFFFF;
  margin: 0;
  font-weight: 300;
}
.tfooter .col {
  flex: 1 1 auto;
  width: 33%;
}
.tfooter .col-logo {
  flex: 0 0 auto;
  width: auto;
}
.tfooter-logo {
  width: 7.9375em;
  height: 7.75em;
  margin: auto;
  display: block;
  margin-bottom: 18;
}
.tfooter .text-holder {
  max-width: 31.33333333em;
  margin: auto;
}
.tfooter .menu-holder {
  display: flex;
  justify-content: space-around;
  margin: auto;
  max-width: 31.33333333em;
}
.tfooter .menu-holder ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: 500;
}
.tfooter .menu-holder ul li a {
  font-weight: 500;
}
.grow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 6.08333333em;
}
.grow-item {
  padding: 2.33333333em 4.75em;
  background: #FFFFFF;
  box-shadow: 0 0.33333333em 2.5em rgba(84, 106, 77, 0.2);
  max-width: 56.5em;
  min-width: 300px;
  margin: 1.33333333em 0.91666667em;
  flex: 1 1 40%;
  position: relative;
}
.grow-icon {
  color: #5E7D32;
  font-size: 4.08333333em;
}
.grow-text .grow-h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 2.33333333em;
  line-height: 1.10714286em;
  margin: 0.85714286em 0;
  /* dark */
  color: #333333;
}
.grow-text p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.33333333em;
  line-height: 1.5em;
  color: #606673;
}
.grow-btn {
  font-family: SFUIDisplay;
  font-size: 1.33333333em;
  line-height: 1.1875em;
  text-align: center;
  border-radius: 3.125em;
  margin-top: 2.5625em;
}
.grow-call {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  z-index: 1;
  width: 9.33333333em;
  height: 9.33333333em;
  padding: 1.16666667em 0.66666667em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0.83333333em;
  padding-left: 3.5em;
  padding-bottom: 3.91666667em;
  cursor: pointer;
}
.grow-call:before {
  width: 17em;
  height: 17em;
  background: #82A84D;
  content: '';
  display: block;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  top: -7.66666667em;
  right: -7.66666667em;
  transition: background .3s;
}
.grow-call i {
  transition: font-size 0.3s;
  font-size: 2.5em;
}
.grow-call p {
  transition: font-size 0.3s;
  font-family: SFUIDisplay;
  font-size: 1.222225em;
  line-height: 1.5em;
  /* identical to box height */
  margin: 0;
  text-align: center;
  color: #FFFFFF;
}
.grow-item.active .grow-form {
  opacity: 1;
  visibility: visible;
}
.grow-item.active .grow-call {
  z-index: 2;
}
.grow-item.active .grow-call i,
.grow-item.active .grow-call p {
  font-size: 0;
}
.grow-item.active .grow-call:before {
  background-color: #5E7D32;
}
.grow-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 2.33333333em 4.75em;
  background: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s;
  padding: 5.33333333em 7.58333333em 1.66666667em 6.58333333em;
}
.grow-form form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.grow-form input {
  width: 100%;
  display: block;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #363935;
  padding-bottom: 0.33333333em;
  margin: auto;
  transition: opacity .3s;
}
.grow-form input:placeholder {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16666667em;
  line-height: 1.57142857em;
  color: #9E9E9E;
  opacity: 0.8;
}
.grow-form .btn {
  width: 100%;
  margin-top: auto;
}
.grow-form .contacts-radioholder {
  min-width: 100px;
  flex-wrap: wrap;
}
.grow-form .contacts-radio {
  margin-bottom: 5px;
}
.integ {
  padding-top: 3.25em;
  padding-bottom: 4em;
  background-color: #82A84D;
}
.integ-h1 {
  margin: 0;
  padding: 0;
}
.integ-h1 h1 {
  color: #fff;
  margin: 0;
}
.integ-loop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.integ-item:last-child .integ-icons:after {
  display: none;
}
.integ-item:nth-child(2n) .integ-icons:after {
  content: '\e816';
}
.integ-icons {
  width: 11.83333333em;
  height: 11.83333333em;
  border-radius: 50%;
  background-color: #98BE61;
  margin: 0 2.75em;
  margin-top: 2.83333333em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}
.integ-icons:before {
  font-size: 5em;
  color: #fff;
  margin: 0;
  width: 100%;
}
.integ-icons:after {
  content: '\e815';
  font-family: fontello;
  font-size: 5.25em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.33333333em;
  color: #fff;
}
@media (max-width: 1020px) {
  .integ .container {
    padding-left: 0;
    padding-right: 0;
  }
  .integ-loop {
    width: 55.55555556em;
    margin: auto;
    max-width: 100%;
  }
  .integ-item:nth-child(3) .integ-icons:after {
    display: none;
  }
}
@media (max-width: 500px) {
  .integ-loop {
    width: 55.55555556em;
    margin: auto;
    max-width: 90%;
  }
  .integ-item:nth-child(3) .integ-icons:after {
    display: flex;
    content: '\e816';
  }
  .integ-item:nth-child(2n) .integ-icons:after {
    display: none;
  }
}
.bred {
  padding: 1.25em 0;
  margin: 0;
  display: flex;
  list-style: none;
}
.bred li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.16666667em;
  line-height: 150.06%;
}
.bred li a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: none;
}
.bred li:after {
  content: '->';
  margin: 0 3px;
}
.bred li:last-child:after {
  display: none;
}
.qform {
  background-image: url(../img/qforbg.png);
  padding: 5.83333333em 0;
}
.qform-wind {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.33333333em);
  border-radius: 2.5em;
  text-align: center;
  padding: 4.16666667em 0;
  max-width: 66.66666667em;
  margin: auto;
}
.qform-h1 {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 3.5em;
  line-height: 1.11904762em;
  margin: 0;
  margin-bottom: 0.69047619em;
  text-align: center;
}
.qform-btn {
  padding-left: 0;
  padding-right: 0;
  width: auto;
  text-align: center;
  font-weight: 700;
  font-size: 1.16666667em;
  min-width: 14.28571429em;
  flex: 0 1 auto;
}
.qform-input-holder {
  display: flex;
  width: 37.58333333em;
  justify-content: center;
  margin: auto;
  background-color: #fff;
  border-radius: 4.16666667em;
}
.qform-input-holder input {
  box-shadow: none;
  border: none;
  flex: 1 1 auto;
  width: 100%;
  min-width: 1;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 700;
  outline: none;
  padding-top: 0.33333333em;
  font-size: 1em;
  border-radius: 4.16666667em;
  padding-left: 1.58333333em;
}
.qform-input-holder input:place-holder {
  color: #BCBCBC;
}
@media (max-width: 1682px) {
  .achiv-item {
    margin: 10px;
  }
  body {
    font-size: 11px;
  }
}
@media (max-width: 1220px) {
  body {
    font-size: 9px;
  }
  .switch-discr p {
    line-height: 1;
  }
  .top-cont {
    width: 46%;
  }
}
@media (max-width: 1020px) {
  .top-slog {
    width: 100%;
  }
  .top-container.container {
    flex-wrap: wrap;
  }
  .top-cont {
    width: 100%;
  }
  .lang {
    margin-left: auto;
  }
  .bottom-container .btns {
    width: auto;
    flex: 0 0 auto;
  }
  .bottom .menu-btn {
    margin-left: auto;
    font-size: 1.11111111em;
    width: 2.5em;
    max-width: 2.5em;
    min-width: 2.5em;
    height: 2.5em;
    padding: 0px;
    display: none;
    border-radius: 0px;
    position: relative;
    order: 2;
    border-radius: 3px;
  }
  .bottom .menu-btn span {
    width: 90%;
    height: 0.3em;
    background-color: #fff;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: margin-top 0.3s 0.3s, margin-bottom 0.3s 0.3s, transform 0.3s;
  }
  .bottom .menu-btn span:first-child {
    margin-top: 0.2em;
  }
  .bottom .menu-btn span:last-child {
    margin-bottom: 0.2em;
  }
  .bottom .menu-btn:hover span {
    background-color: #82a84d;
  }
  .bottom .menu-btn.active span {
    background-color: #82a84d;
    transform: rotate(45deg);
    transition: margin-top 0.3s, margin-bottom 0.3s, transform 0.3s 0.3s;
  }
  .bottom .menu-btn.active span:first-child {
    margin-top: 0.9em;
  }
  .bottom .menu-btn.active span:last-child {
    margin-bottom: 0.9em;
    transform: rotate(-45deg);
  }
  .bottom .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #fff;
    width: 100%;
    min-width: 100%;
    padding-bottom: 5px;
  }
  .slick-wind {
    font-size: 6px;
  }
  .switch-item {
    width: 100%;
    height: 13.33333333em;
    border: 1px solid #cccc;
    margin: 0px;
  }
  .switch-top {
    flex-wrap: wrap;
  }
  .switch-item img {
    width: 100%;
  }
  .switch-item .footer {
    display: none;
  }
  .switch-content__cont {
    flex-wrap: wrap;
  }
  .switch-content__left {
    width: 100%;
    flex: 1 1 100%;
    padding: 50px;
    padding-top: 0px;
  }
  .advantages-item {
    width: 100%;
    flex: 1 1 100%;
    max-width: 100%;
  }
  .achiv-item {
    width: 50%;
    flex: 1 1 50%;
    max-width: 50%;
    margin: 0px;
    padding: 10px;
  }
  .achiv-loop {
    flex-wrap: wrap;
  }
  .container.contacts-cont {
    flex-wrap: wrap;
  }
  .contacts-half {
    width: 100%;
  }
  .contacts:before,
  .contacts:after {
    height: 50%;
    bottom: 0px;
    top: auto;
    left: 0px;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .top-container.container {
    padding: 0 15px;
  }
  .top-cont {
    flex-wrap: wrap;
  }
  .top-cont {
    flex-wrap: wrap;
    text-align: center;
  }
  .bottom-container .btns .btn {
    width: 50%;
  }
  .bottom-container .btns button {
    margin: 3px;
    min-width: 100px;
  }
  .bottom-container .btns {
    width: auto;
    max-width: 100%;
    margin: 0px;
    flex: 1 1 auto;
  }
  .bottom-container .btns button:first-child {
    margin-right: 3px;
  }
  .container.bottom-container {
    padding: 0px 15px;
  }
  .bottom .menu-btn {
    display: block;
  }
  .btns {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }
  .bottom .menu {
    flex-wrap: wrap;
  }
  .bottom .menu a {
    display: block;
    width: 100%;
    margin: 5px 0px;
  }
  .bottom .menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #fff;
    width: 100%;
    min-width: 100%;
    padding-bottom: 5px;
    flex-wrap: wrap;
    font-size: 12px;
    max-height: 0px;
    overflow: hidden;
    padding: 0px;
    transition: max-height 0.3s, padding 0s 0.3s;
  }
  .bottom .menu.active {
    transition: max-height 0.3s;
    padding: 15px;
    max-height: 999px;
  }
  .switch-container {
    padding: 15px;
  }
  .switch-content__left {
    padding: 20px;
  }
  .switch-container {
    padding: 15px;
  }
  .switch-content__left {
    padding: 20px;
  }
  .advantages-item {
    flex-wrap: wrap;
    background: #fff;
  }
  .advantages-img img {
    position: static;
  }
  .advantages-row {
    padding: 15px;
  }
  .tfooter .container {
    flex-wrap: wrap;
  }
  .tfooter .container .col {
    width: 100%;
    order: 2;
  }
  .tfooter .container .col-logo {
    order: 1;
  }
  .tfooter .text-holder {
    margin: 0px;
  }
  .slick-dts {
    bottom: auto;
    top: 100%;
    padding-top: 20px;
  }
  .slick-wind {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(0px);
  }
  .container {
    padding: 0px;
  }
  .main-h1 h1 {
    font-size: 30px;
  }
  .advantages-img {
    order: 2;
  }
  a.partners-link {
    max-width: 42%;
  }
  .partners {
    flex-wrap: wrap;
  }
  .tfooter .text-holder {
    margin: 20px auto;
  }
  .col.col-logo {
    text-align: center;
  }
  .switch-discr {
    width: 72%;
    right: 0;
    border-radius: 30px;
  }
  .switch-item.active .switch-discr,
  .switch-item:hover .switch-discr {
    width: auto;
    width: 72%;
    border-radius: 0;
  }
  .form-holder {
    padding: 0px 15px;
  }
  .switch-content__text p {
    font-size: 15px;
  }
  .advantages-text .text p {
    font-size: 15px;
  }
  .achiv-item {
    display: block;
    max-width: 100%;
    text-align: center;
  }
  header .top-container.container {
    display: none;
  }
  header .top-container.container.active {
    display: block;
  }
}
@media (max-width: 400px) {
  .achiv-item {
    display: block;
    max-width: 100%;
    text-align: center;
  }
}
