.integ{
	padding-top: (39/@bfz)*1em;
	padding-bottom: (48/@bfz)*1em;
	background-color: #82A84D;
	&-h1{
		margin: 0;
		padding: 0;
		h1{
			color:#fff;
			margin: 0;
		}
	}
	&-loop{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	&-item{
		&:last-child{
			.integ-icons:after{
				display: none;
			}
		}
		&:nth-child(2n){
			.integ-icons:after{
				content: '\e816';
			}
		}
	}
	&-icons{
		width: (142/@bfz)*1em;
		height: (142/@bfz)*1em;
		border-radius: 50%;
		background-color:#98BE61;
		margin: 0 (33/@bfz)*1em;
		margin-top: (34/@bfz)*1em;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		flex:0 0 auto;
		&:before{
			font-size: (60/@bfz)*1em;
			color: #fff;
			margin: 0;
			width: 100%;
		}
		&:after{
			content: '\e815';
			font-family: fontello;
			@tfz:63;
			font-size:(@tfz/@bfz)*1em ;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: -(21/@tfz)*1em;
			color: #fff;
		}
	}
}
@media (max-width: 1020px)
{
	.integ{
		.container{
			padding-left: 0;
			padding-right: 0;
		}
		&-loop{
			width: (500/9)*1em;
			margin: auto;
			max-width: 100%;

		}
		&-item:nth-child(3) &-icons:after{
			display: none;
		}
	}
}
@media (max-width: 500px)
{
	.integ{
		&-loop{
			width: (500/9)*1em;
			margin: auto;
			max-width: 90%;

		}
		&-item:nth-child(3) .integ-icons:after{
			display: flex;
			content: '\e816';
		}
		&-item:nth-child(2n) .integ-icons:after{
			display: none;
		}
	}
}
