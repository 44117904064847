/*MAIN STYLES*/
body{
  padding: 0px;
  margin: 0px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}
*:before,*:after{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



/* loader */
.icon-load {
  background: url(../img/loader.gif) no-repeat;
  background-color:#fff;
  background-position: 50%;
  width: 100%;
  height: 100%;
  position: fixed;
  top:  0px;
  left:   0px;
  right:  0px;
  bottom: 0px;
  display: none;
  z-index: 1000;
}
.loaded .icon-load {
  display: block;
}
/*CONTENT*/
.content {
  width: 100%;
  min-width: 320px;
  text-align: left;
}

.test{
  display: block;
  width: 100%;
  height: 100%;
  min-height: 10px;
  min-width: 10px;
  background: #c00;
  box-shadow: inset 0px 0px 0px 1px #000;
}
