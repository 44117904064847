.advantages{
	&-container{
		padding: 0;
		overflow: hidden;
	}
	&-cont,&-row{
		display: flex;
		flex-direction: column;
	}

	&-item{
		background: #FFFFFF;
		box-shadow: 0 (4/@bfz)*1em (20/@bfz)*1em rgba(0, 0, 0, 0.15);
		flex:1 1 75%;
		max-width: 75%;
		margin: (33/@bfz)*1em 0;
		margin-left: auto;
		display: flex;
		min-width: 55%;
		position: relative;
		z-index: 2;
		left: 100%;
		transition: left .3s, right .3s;

	}
	&-row{
		position: relative;
		.advantages-dec{
			position: absolute;
			left: 0;
			bottom:( 125/@bfz)*1em;
			z-index: 1;
		}
		&:nth-child(2n){
			.advantages-item{
				margin-left: 0;
				margin-right: auto;
				left: auto;
				right: 100%;
				.advantages-text{
					order: 1
				}
			}
			.advantages-dec{
				right: 0;
				left: auto;
			}
		}
	}
	&-item.show{
		left: 0;

	}
	&-row:nth-child(2n) &-item.show{
		right: 0;
	}
	&-text,&-img{
		flex:1 1 50%;

	}
	&-img{
		position: relative;
		overflow: hidden;
		img{
			position: absolute;
			margin: auto;
			vertical-align: top;
			width: auto;
			min-height: 100%;

			position: absolute;
			margin: auto;
			vertical-align: top;
			width: auto;
			min-height: 100%;
			top: -100%;
			bottom: -100%;
			left: -100%;
			right: -100%;

		}
	}
	&-text{
		display: flex;
		align-items: center;
		justify-content: center;
		.text{
			padding: 0 (65/@bfz)*1em;
			display: block;
			h1{
				font-family: Comfortaa;
				font-style: normal;
				font-weight: bold;
				@tfz:44;
				font-size:(@tfz/@bfz)*1em ;
				line-height: (49/@tfz)*1em;
				margin-bottom: (44/@tfz)*1em;
			}
			p{
				font-family: Roboto;
				font-style: normal;
				font-weight: normal;
				@tfz:16;
				font-size:(@tfz/@bfz)*1em ;
				line-height: (24/@tfz)*1em;
				color: #606673;
			}
			.btn{
				@tfz:16;
				width: auto;
				min-width: inherit;
				margin-top: (45/@tfz)*1em;
				margin-bottom: (20/@tfz)*1em;
			}
		}
	}

	.partners-link {
		width: 100px;
	}


	.partners {
		flex-wrap: wrap;
	}

	.achiv-item {
		width: 100%;
		max-width: 100%;
		flex: 0 0 auto;
		width: 200px;
	}
	&-arrow{
		.advantages{
			&-text{
				.text{
					padding-left: 0;
					margin: (50/@bfz)*1em 0;
					h1{
						@tfz:40;
						font-size:(@tfz/@bfz)*1em ;
						&:before{
							@itfz:56;
							font-size:(@itfz/@tfz)*1em ;
							color: #82A84D;
							margin-right: (24/@itfz)*1em;
						}
					}
				}
			}
			&-item{
				box-shadow: none;
				width: 80%;
				&:before{
					content: '';
					width: (143/@bfz)*1em;
					height: (156/@bfz)*1em;
					position: absolute;
					bottom: 100%;
					margin: auto;
					margin-bottom: (20/@bfz)*1em;
					background-image: url(../img/darrow.png);
					background-size: auto 100%;
					transform: rotateY(180deg);
					right: 20%;

				}
			}
			&-row:first-child .advantages-item:before
			{
				display: none;
			}
			&-row:nth-child(2n) .advantages-item:before{
				transform: rotateY(0deg);
				right: initial;
				left: 20%;
			}

			&-row:nth-child(2n) .advantages-item .advantages-text .text{
				padding-right: 0;
				padding-left: (60/@bfz)*1em;
			}

		}
	}
}


@media (max-width: 1020px){
	.advantages-arrow{
		.advantages{
			&-item{
				width: 100%;
				margin-bottom: 100px;
				.text{
					padding: 0 15px;
				}
			}
			&-item:before{
				right: 10%;
			}
			&-row:nth-child(2n) .advantages-item:before{
				left: 10%;
			}
		}
	}
}

@media (max-width: 500px){
	.advantages-arrow .advantages-row:nth-child(2n) .advantages-item .advantages-text .text{
		padding-left: 15px;
	}
}