
header{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 100;
	@tfz:14px;
	p,a{
		color: #363935;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;

		font-size:(@tfz/@bfz)*1em ;
		line-height: 150.06%;
		margin: 0;
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}
.top{
	@tfz:14px;
	border-bottom: (1px/@bfz)*1em solid #E6E6E6;
	&-container{
		display: flex;
		line-height: (37px/@bfz)*1em;
		align-items: center;
	}
	

	&-cont{
		margin-left: auto;
		display: flex;
		align-items: center;
		a{
			margin: 0 (10px/@tfz)*1em;
			&:before{
				margin-right: (5px/@bfz)*1em;
			}
		}
	}
}
.bottom{
	box-shadow: 0px 22px 20px -20px rgba(0, 0, 0, 0.15);
	background-color: #fff;
	&.scrool{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
	&-container{
		display: flex;
		align-items: center;
		padding-top: (12px/@bfz)*1em;
		padding-bottom: (12px/@bfz)*1em;
		.btns{
			margin-left: auto;
			flex:  1 1 auto;
			margin-left: auto;
			text-align: right;
			button:first-child {
				margin-right: (25/@bfz)*1em;
			}
		}
	}
	.logo{
		img{
			max-width: (64px/@bfz)*1em;
			max-height: (64px/@bfz)*1em;
		}
	}
	.menu{
		display: flex;
		flex: 1 1 auto;
		justify-content: space-around;
		max-width: 55%;
		&-btn{
			display: none;
		}
	}
}

.social{
	padding: 0;
	display: flex;
	list-style: none;
	margin: 0;
	a{
		margin: 0 (5px/@bfz)*1em;
		color:#9E9E9E;
		&:before{
			margin: 0;
		}
	}
}

.lang{
	position: relative;
	margin-left: (20px/@bfz)*1em;
	&-select{
		display: flex;
		align-items: center;
		&:before{
			order:2;
			font-size: (10px/@bfz)*1em;
		}
	}
	&-top{
		display: flex;
		cursor: pointer;
		img{
			width: (20px/@bfz)*1em;
			height: (20px/@bfz)*1em;
		}
	}
	&.active &-hider{
		max-height: 999px;
	}
	&-hider{
		overflow: hidden;
		list-style: none;
		max-height: 0px;
		position: absolute;
		margin: auto;
		margin-top: (8px/@bfz)*1em;
		top: 100%;
		z-index: 1;
		right: 0;
		padding: 0;
		width: (130px/@bfz)*1em;
	}
	&-list{
		margin: 0;
		border:(1px/@bfz)*1em solid #E6E6E6;
		border-radius: (6/@bfz)*1em;
		list-style: none;
		padding: (10px/@bfz)*1em (20px/@bfz)*1em;
		background-color: #fff;
		li{
			display: flex;
			padding: 5px 0;
			img{
				margin-right: (5px/@bfz)*1em;

			}
		}
	}
}
