.banner{
	&-holder{
		text-align: center;
		position: relative;
		z-index: 2;
	}
	&-img{
		max-width: (1235px/@bfz)*1em;
		border-radius: (20px/@bfz)*1em;
		margin: (75px/@bfz)*1em auto;
		display: block;
		img{
			
			max-width: 100%;
		}
	}

	&-decor{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		z-index: -1;
		&__1{
			position: absolute;
			top: 0;
			right: 0;
			width: (159px/@bfz)*1em;
			height: (192px/@bfz)*1em
		}
		&__2{
			position: absolute;
			bottom: (70px/@bfz)*1em	;
			left: 0;
			width: (151px/@bfz)*1em;
			height: (210px/@bfz)*1em;
		}

	}
}