
.icon-mail:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-facebook:before { content: '\e802'; } /* '' */
.icon-linkedin:before { content: '\e803'; } /* '' */
.icon-twit:before { content: '\e804'; } /* '' */
.icon-arrow-down:before { content: '\e805'; } /* '' */
.icon-chevron-left:before { content: '\e806'; } /* '' */
.icon-chevron-right:before { content: '\e807'; } /* '' */
.icon-turkey:before { content: '\e808'; } /* '' */
.icon-cancel:before { content: '\e809'; } /* '' */
.icon-wheat:before { content: '\e80a'; } /* '' */
.icon-chicken:before { content: '\e80b'; } /* '' */
.icon-left-dir:before { content: '\e80c'; } /* '' */
.icon-right-dir:before { content: '\e80d'; } /* '' */
.icon-phone-call:before { content: '\e80e'; } /* '' */
.icon-placeholder:before { content: '\e80f'; } /* '' */
.icon-envelope:before { content: '\e810'; } /* '' */
.icon-vk-social-logotype:before { content: '\e811'; } /* '' */
.icon-facebook-logo-button:before { content: '\e812'; } /* '' */
.icon-googleplus-logo:before { content: '\e813'; } /* '' */
.icon-ring:before { content: '\e814'; } /* '' */
.icon-paa:before { content: '\e815'; } /* '' */
.icon-pad:before { content: '\e816'; } /* '' */
.icon-hay-rolls:before { content: '\e817'; } /* '' */
.icon-industry:before { content: '\e818'; } /* '' */
.icon-truck:before { content: '\e819'; } /* '' */
.icon-fruit:before { content: '\e81a'; } /* '' */
.icon-hends:before { content: '\e81b'; } /* '' */